import { FC } from "react";

import { observer } from "mobx-react-lite";

import BuildingFilterStore from "components/TableFilter/BuildingFilter.store";
import { TableFilterContext } from "components/TableFilter/TableFilter.context";

import UpdateDataTabs from "./components/UpdateData.tabs";
import { MainPaddingContent } from "./UpdateData.styled";

const TableFilterInstanceStore = new BuildingFilterStore();

const UpdateData: FC = () => (
	<TableFilterContext.Provider value={TableFilterInstanceStore}>
		<MainPaddingContent>
			<UpdateDataTabs />
		</MainPaddingContent>
	</TableFilterContext.Provider>
);

export default observer(UpdateData);
