import styled from "styled-components";
import { Space, Text } from "tap2visit-ui-kit";

import { ReactComponent as PhoneIcon } from "public/SmartPhone.svg";

import CitizensDrawerStore from "../../../components/Citizens/store/Citizens.drawer.store";
import {
	CitizenRealtyWrapper,
	CitizenRealtyWrapperFlex,
	PersonalInformationWrapper,
	CitizenRealtyTitleAppLogin,
} from "../../../components/Citizens/style/Citizen.styled";
import formatPhoneNumber from "../../../utils/format/formatPhone";

import { CitizenDrawerTextHeaderBlock } from "./CitizenTexts";

const CitizenAppLogin = () => {
	const citizenLogin = CitizensDrawerStore.selectedUser.contacts?.find((contact) => contact.isDefault)?.value ?? "Данные отсутствуют";

	return (
		<Space size={8} direction="column">
			<PersonalInformationWrapper>
				<CitizenDrawerTextHeaderBlock text={"Логин в приложении"} />
			</PersonalInformationWrapper>

			<Space width="100%">
				<CitizenRealtyWrapper>
					<CitizenAppLoginWrapperFlex>
						<PhoneIcon />
						<Space size={1} direction="column" align="start">
							<Text type="table-cell-l-medium">{formatPhoneNumber(citizenLogin)}</Text>
							<Text color="rgba(18, 18, 18, 0.6)" type="table-cell-m-regular">
								Номер телефона для входа в приложение
							</Text>
						</Space>
					</CitizenAppLoginWrapperFlex>
				</CitizenRealtyWrapper>
			</Space>
		</Space>
	);
};

const CitizenAppLoginWrapperFlex = styled(CitizenRealtyWrapperFlex)`
	padding: 8px 12px;
	justify-content: start;
	svg {
		margin-right: 8px;
	}
`;
export default CitizenAppLogin;
