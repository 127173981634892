import { FC, useState } from "react";
import React from "react";

import { observer } from "mobx-react-lite";
import { useMutation } from "react-query";
import { Button, Space, colors } from "tap2visit-ui-kit";
import { BoldEdit } from "t2v-icons";

import { createDocumentApi, updateDocumentApi } from "api/api.documents";
import { IDocumentCreate } from "interfaces/IDocuments";
import { ValidationStatuses } from "modules/validation/Validation.store";
import { cleanString } from "utils/format/cleanString";

import { updateUserByIdApi } from "../../../api/api.clients";
import CitizenFiles from "../../../components/Citizens/components/CitizenFiles";
import UserEditInput from "../../../components/Citizens/components/UserEdit.input";
import useNewFileState from "../../../components/Citizens/hooks/useNewFileState";
import useUploadCitizenFiles from "../../../components/Citizens/hooks/useUploadCitizenFiles";
import { citizenCreateStoreInputMaxLength } from "../../../components/Citizens/store/CitizenCreate.store";
import CitizensDrawerStore from "../../../components/Citizens/store/Citizens.drawer.store";
import {
	CitizenPersonalEdit,
	CitizenPersonalEditFooter,
	CitizenPersonalEditWrapper,
	CitizenWrapper,
	PersonalInformationWrapper,
} from "../../../components/Citizens/style/Citizen.styled";
import { CitizenRealtyWrapper } from "../../../components/Citizens/style/Citizen.styled";
import useCitizenDocumentByType from "../hooks/useCitizenDocumentByType";
import useRefreshCitizenAfterUpdate from "../hooks/useRefreshCitizenAfterUpdate";
import CitizenInnValidationStore from "../store/CitizenInnValidationStore";

import {
	CitizenDrawerInformationBlockHeader,
	CitizenDrawerInformationBlockDescription,
	CitizenDrawerTextHeaderBlock,
} from "./CitizenTexts";

export type TCitizenInnOnChange = (data: { inn: string; files: File[] }) => void;
interface ICitizenInn {
	onlyEditable?: boolean; // ? when we creating a new user
	onDataChange?: TCitizenInnOnChange; // ? for a new user
}

const CitizenInn: FC<ICitizenInn> = (props) => {
	const [edit, setEdit] = useState(!!props.onlyEditable);
	const [inn, setInn] = useState("");
	const [initialInn, setInitialInn] = useState("");

	const citizenDocument = useCitizenDocumentByType({ type: ["INN"] });

	const { needToRemoveFileIds, newFiles, setNeedToRemoveFileIds, setNewFiles } = useNewFileState();
	const refreshUser = useRefreshCitizenAfterUpdate({
		setIsEdit: setEdit,
		setNewFiles,
	});
	const uploadFiles = useUploadCitizenFiles({
		needToRemoveFileIds: needToRemoveFileIds,
		newFiles: newFiles,
		citizenDocument: citizenDocument,
	});

	const updateUser = useMutation({
		mutationFn: updateUserByIdApi,
	});

	const createDocument = useMutation(createDocumentApi);
	const updateDocument = useMutation(updateDocumentApi);

	React.useEffect(() => {
		if (citizenDocument) {
			setInn(citizenDocument.number);
			setInitialInn(citizenDocument.number);
		}
	}, [citizenDocument]);

	React.useEffect(() => {
		if (props.onDataChange) {
			props.onDataChange({
				inn,
				files: newFiles,
			});
		}
	}, [inn, newFiles]);

	const checkIfInnChanged = (currentInn: string, initialInn: string): boolean => cleanString(currentInn) !== cleanString(initialInn);

	const changeInn = async () => {
		const newFileIds = await uploadFiles();
		const cleanedInn = cleanString(inn);

		const isValid = CitizenInnValidationStore.checkValidation({ inn: cleanedInn });
		if (!isValid) {
			return;
		}

		const dataInputs = CitizensDrawerStore.selectedUser;

		const documentBody: IDocumentCreate = {
			type: "INN",
			attachmentType: "CLIENT",
			targetId: CitizensDrawerStore.selectedUser.id,
			sourceId: CitizensDrawerStore.selectedUser.id,
			fileIds: newFileIds,
			number: cleanedInn,
		};

		if (citizenDocument) {
			await updateDocument.mutateAsync({
				body: documentBody,
				path: {
					id: citizenDocument.id,
				},
			});
		} else {
			await createDocument.mutateAsync({
				body: documentBody,
			});
		}

		await updateUser.mutateAsync({
			path: { id: dataInputs.id },
			body: {
				contacts: dataInputs.contacts.filter((contact) => contact.value),
				firstName: dataInputs.firstName,
				lastName: dataInputs.lastName,
				middleName: dataInputs.middleName,
			},
		});
		setInn(inn);
		refreshUser();
	};

	const handleInnChange = (value: string) => {
		setInn(value);
		const innChanged = checkIfInnChanged(value, initialInn);
		CitizensDrawerStore.setHasUnsavedData(innChanged);
	};

	const cancelEdit = () => {
		setEdit(false);
		setInn(citizenDocument?.number);
		setNewFiles([]);
		setNeedToRemoveFileIds([]);
		CitizensDrawerStore.setHasUnsavedData(false);
		CitizensDrawerStore.setIsModalVisible(false);
		CitizenInnValidationStore.resetValidation();
		setInitialInn(citizenDocument?.number);
	};

	React.useEffect(() => {
		if (CitizensDrawerStore.shouldExitEditMode.inn) {
			cancelEdit();
			CitizensDrawerStore.setShouldExitEditMode("inn", false);
		}
	}, [CitizensDrawerStore.shouldExitEditMode.inn]);

	const validationFields = CitizenInnValidationStore.validationData;
	const errorMessage = CitizenInnValidationStore.getMessageIfError("inn");

	return (
		<Space size={8} align="start" width="100%" direction="column">
			<PersonalInformationWrapper>
				<CitizenDrawerTextHeaderBlock text={"ИНН"} />

				{!props.onlyEditable && !edit && (
					<Button size="small" onClick={() => setEdit(true)} shape="square" typeButton="secondary" icon={BoldEdit} />
				)}
			</PersonalInformationWrapper>
			{edit && (
				<CitizenPersonalEditWrapper background={props.onlyEditable ? colors.surfaceNeutralBg2 : undefined}>
					<CitizenPersonalEdit>
						<UserEditInput
							label={"ИНН"}
							required
							value={inn}
							maxLength={citizenCreateStoreInputMaxLength.inn}
							onChange={handleInnChange}
							isError={validationFields.inn !== ValidationStatuses.VALID}
							placeholder="0000000000"
							message={errorMessage}
						/>
						<CitizenFiles
							fileIds={citizenDocument?.fileIds}
							needToRemoveFileIds={needToRemoveFileIds}
							setNeedToRemoveFileIds={setNeedToRemoveFileIds}
							newFiles={newFiles}
							setNewFiles={setNewFiles}
							title="Изображение документа"
						/>
					</CitizenPersonalEdit>

					{!props.onlyEditable && (
						<CitizenPersonalEditFooter>
							<Button typeButton="secondary" onClick={cancelEdit}>
								Отменить
							</Button>
							<Button onClick={changeInn}>Сохранить</Button>
						</CitizenPersonalEditFooter>
					)}
				</CitizenPersonalEditWrapper>
			)}
			{!edit && (
				<>
					<CitizenRealtyWrapper>
						<CitizenWrapper>
							<Space align="start" size={1} direction="column">
								<CitizenDrawerInformationBlockHeader text={"ИНН"} />
								<CitizenDrawerInformationBlockDescription text={inn ? inn : "Данные отсутствуют"} />
							</Space>
						</CitizenWrapper>
					</CitizenRealtyWrapper>

					{!!citizenDocument?.fileIds.length && <CitizenFiles disabled fileIds={citizenDocument?.fileIds} title="Изображение документа" />}
				</>
			)}
		</Space>
	);
};

export default observer(CitizenInn);
