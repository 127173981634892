import React, { useState } from "react";

import { observer } from "mobx-react-lite";
import { useMutation } from "react-query";
import { BoldEdit } from "t2v-icons";
import { Button, colors, Space, Text } from "tap2visit-ui-kit";

import { queryClient } from "index";
import { TUserData } from "interfaces/IClient";
import { ValidationStatuses } from "modules/validation/Validation.store";

import { updateUserByIdApi } from "../../../api/api.clients";
import UserEditInput from "../../../components/Citizens/components/UserEdit.input";
import CitizensDrawerStore from "../../../components/Citizens/store/Citizens.drawer.store";
import {
	CitizenPersonalEdit,
	CitizenPersonalEditFooter,
	CitizenPersonalEditWrapper,
	CitizenRealtyDivider,
	CitizenRealtyWrapper,
	CitizenWrapper,
	PersonalInformationWrapper,
} from "../../../components/Citizens/style/Citizen.styled";
import { QueriesKeys } from "../../../interfaces/queriesKeys";
import СitizenPersonalInformationValidationStore from "../store/СitizenPersonalInformationValidation.store";

import CitizenConfirmationModal from "./CitizenConfirmationModal";
import { CitizenDrawerTextHeaderBlock } from "./CitizenTexts";

const CitizenPersonalInformation = () => {
	const [edit, setEdit] = useState(false);
	const [lastName, setLastName] = useState(CitizensDrawerStore.selectedUser.lastName);
	const [firstName, setFirstName] = useState(CitizensDrawerStore.selectedUser.firstName);
	const [middleName, setMiddleName] = useState(CitizensDrawerStore.selectedUser.middleName);
	const [residentialAddress, setResidentialAddress] = React.useState(CitizensDrawerStore.selectedUser.residentialAddress);

	const updateUser = useMutation({
		mutationFn: updateUserByIdApi,
	});

	const checkIfDataChanged = (newData: TUserData) =>
		newData.firstName !== CitizensDrawerStore.selectedUser.firstName ||
		newData.lastName !== CitizensDrawerStore.selectedUser.lastName ||
		newData.middleName !== CitizensDrawerStore.selectedUser.middleName ||
		newData.residentialAddress !== CitizensDrawerStore.selectedUser.residentialAddress;

	const handleInputChange = (setter: React.Dispatch<React.SetStateAction<string>>, value: string, field: string) => {
		setter(value);
		const newData = {
			firstName: firstName,
			lastName: lastName,
			middleName: middleName,
			residentialAddress: residentialAddress,
			[field]: value,
		};
		CitizensDrawerStore.setHasUnsavedData(checkIfDataChanged(newData));
	};

	const resetFileIds = () => {
		setLastName(CitizensDrawerStore.selectedUser.lastName || "");
		setFirstName(CitizensDrawerStore.selectedUser.firstName || "");
		setMiddleName(CitizensDrawerStore.selectedUser.middleName || "");
		setResidentialAddress(CitizensDrawerStore.selectedUser.residentialAddress || "");
		CitizensDrawerStore.setHasUnsavedData(false);
	};

	const handleCancelClick = () => {
		setEdit(false);
		resetFileIds();
	};

	const changePersonalInformation = async () => {
		const isValid = СitizenPersonalInformationValidationStore.checkValidation({
			firstName,
			lastName,
			middleName,
			residentialAddress,
		});

		if (!isValid) {
			return;
		}

		const dataInputs = CitizensDrawerStore.selectedUser;

		const updatedUser = await updateUser.mutateAsync({
			path: { id: dataInputs.id },
			body: {
				contacts: dataInputs.contacts.filter((contact) => contact.value),
				firstName,
				lastName,
				inn: dataInputs.inn,
				insuranceNumber: dataInputs.insuranceNumber,
				middleName: middleName ? middleName : null,
				residentialAddress: residentialAddress ? residentialAddress : null,
			},
		});
		if (updateUser.isSuccess) {
			const selectedUser = { ...CitizensDrawerStore.selectedUser, firstName, lastName, middleName, residentialAddress };
			CitizensDrawerStore.changeSelectedUser({ user: selectedUser });
		}

		CitizensDrawerStore.openDrawer({ mode: "visible", user: updatedUser });

		queryClient.invalidateQueries([QueriesKeys.clients]);
		queryClient.invalidateQueries([QueriesKeys.user, CitizensDrawerStore.selectedUser.id]);
		setEdit(false);
		CitizensDrawerStore.setHasUnsavedData(false);
	};

	const validationFields = СitizenPersonalInformationValidationStore.validationData;
	const errorMessageFirstName = СitizenPersonalInformationValidationStore.getMessageIfError("firstName");
	const errorMessageLastName = СitizenPersonalInformationValidationStore.getMessageIfError("lastName");
	const errorMessageMiddleName = СitizenPersonalInformationValidationStore.getMessageIfError("middleName");
	const errorMessageResidentialAddress = СitizenPersonalInformationValidationStore.getMessageIfError("residentialAddress");

	React.useEffect(() => {
		if (CitizensDrawerStore.shouldExitEditMode.info) {
			setEdit(false);
			CitizensDrawerStore.setShouldExitEditMode("info", false);
		}
	}, [CitizensDrawerStore.shouldExitEditMode.info]);

	return (
		<Space size={8} width="100%" direction="column">
			<PersonalInformationWrapper>
				<CitizenDrawerTextHeaderBlock text={"Персональная информация"} />
				{!edit && (
					<Button size="small" shape="square" typeButton="secondary" onClick={() => setEdit((status) => !status)} icon={BoldEdit} />
				)}
			</PersonalInformationWrapper>
			{edit && (
				<CitizenPersonalEditWrapper>
					<CitizenPersonalEdit>
						<UserEditInput
							maxLength={50}
							isError={validationFields.lastName !== ValidationStatuses.VALID}
							message={errorMessageLastName}
							label={"Фамилия"}
							required
							value={lastName}
							onChange={(value) => handleInputChange(setLastName, value, "lastName")}
						/>
						<UserEditInput
							isError={validationFields.firstName !== ValidationStatuses.VALID}
							maxLength={50}
							message={errorMessageFirstName}
							label={"Имя"}
							required
							value={firstName}
							onChange={(value) => handleInputChange(setFirstName, value, "firstName")}
						/>
						<UserEditInput
							isError={validationFields.middleName !== ValidationStatuses.VALID}
							message={errorMessageMiddleName}
							label={"Отчество"}
							value={middleName}
							onChange={(value) => handleInputChange(setMiddleName, value, "middleName")}
						/>
						<UserEditInput
							isError={validationFields.residentialAddress !== ValidationStatuses.VALID}
							message={errorMessageResidentialAddress}
							label={"Адрес проживания"}
							as="textarea"
							value={residentialAddress}
							onChange={(value) => handleInputChange(setResidentialAddress, value, "residentialAddress")}
						/>
						<Text color={colors.iconNeutralSecondary} type="small-regular">
							Введите адрес фактического проживания, адрес по прописке указывается в разделе «Паспорт»
						</Text>
					</CitizenPersonalEdit>

					<CitizenPersonalEditFooter>
						<Button typeButton="secondary" onClick={handleCancelClick}>
							Отменить
						</Button>
						<Button onClick={changePersonalInformation}>Сохранить</Button>
					</CitizenPersonalEditFooter>
				</CitizenPersonalEditWrapper>
			)}
			{!edit && (
				<Space width="100%" direction="column">
					<CitizenRealtyWrapper>
						<CitizenWrapper>
							<Space align="start" size={1} direction="column">
								<Text color={colors.iconNeutralDefault} type="small-regular">
									ФИО
								</Text>
								<Text color={colors.textNeutralPrimary} type="base-medium">
									{CitizensDrawerStore.selectedUser.lastName} {CitizensDrawerStore.selectedUser.firstName}{" "}
									{CitizensDrawerStore.selectedUser.middleName}
								</Text>
							</Space>
						</CitizenWrapper>
						<CitizenRealtyDivider />
						<CitizenWrapper>
							<Space align="start" size={1} direction="column">
								<Text color={colors.iconNeutralDefault} type="small-regular">
									Адрес проживания
								</Text>
								<Text color={colors.textNeutralPrimary} type="base-medium">
									{CitizensDrawerStore.selectedUser.residentialAddress ?? "Адрес отсутствует"}
								</Text>
							</Space>
						</CitizenWrapper>
					</CitizenRealtyWrapper>
				</Space>
			)}
		</Space>
	);
};

export default observer(CitizenPersonalInformation);
