import React, { FC } from "react";

import { queryClient } from "index";
import { IRequestUser } from "interfaces/IRequest";
import { QueriesKeys } from "interfaces/queriesKeys";
import { approveRequestApi, restoreRequestApi } from "pages/accessRequest/api/api.request";
import AccessRequestCanApproveRequestStore from "pages/accessRequest/store/AccessRequestCanApproveRequest.store";
import AccessRequestRejectModalStore from "pages/accessRequest/components/ModalCommentRejection/store/AccessRequestRejectModal.store";
import AccessRequestTabStore from "pages/accessRequest/store/AccessRequestTab.store";
import { useMutation } from "react-query";
import styled from "styled-components";
import { BoldUserdelete, BoldUserchecked5, BoldRefresh1 } from "t2v-icons";
import { Button } from "tap2visit-ui-kit";
import { observer } from "mobx-react-lite";
import BaseModal from "components/Modals/BaseModal";

interface IActionButtons {
	requestUser: IRequestUser;
	compareUserId: string | undefined;
	onRequestSuccess: () => void;
}

const ActionButtons: FC<IActionButtons> = (props) => {
	const onApproveClick = () => {
		approveRequest.mutateAsync();
	};

	const approveRequest = useMutation({
		mutationFn: () => approveRequestApi({ body: { requestId: props.requestUser.id, baseClientId: props.compareUserId } }),
		onSuccess: () => {
			props.onRequestSuccess();
			queryClient.invalidateQueries([QueriesKeys.requests]);
		},
	});

	const restoreRequest = useMutation({
		mutationFn: () => restoreRequestApi({ path: { requestId: props.requestUser.id } }),
		onSuccess: () => {
			props.onRequestSuccess();
			AccessRequestTabStore.setActiveTab("mobileApp");
			queryClient.invalidateQueries([QueriesKeys.requests]);
		},
	});

	const onOpenRejectCommentModal = () => {
		AccessRequestRejectModalStore.showModal({ requestId: props.requestUser.id });
	};

	return (
		<>
			{props.requestUser.status === "NEW" && (
				<WrapperUserButton>
					<Button
						icon={(args) => <BoldUserdelete {...args} style={{ height: "18px", width: "18px" }} />}
						variant="red"
						onClick={onOpenRejectCommentModal}>
						Отклонить
					</Button>
					<Button
						icon={(args) => <BoldUserchecked5 {...args} style={{ height: "18px", width: "18px" }} />}
						disabled={!AccessRequestCanApproveRequestStore.canApproveRequest}
						onClick={onApproveClick}>
						Принять
					</Button>
				</WrapperUserButton>
			)}

			{props.requestUser.status === "CANCELED" && (
				<WrapperUserButton>
					<Button size="small" typeButton="secondary" onClick={onOpenRejectCommentModal}>
						Комментарий
					</Button>
					<Button size="small" shape="square" typeButton="primary" icon={BoldRefresh1} onClick={() => restoreRequest.mutateAsync()} />
				</WrapperUserButton>
			)}
		</>
	);
};

export default observer(ActionButtons);

const WrapperUserButton = styled.div`
	display: flex;
	button {
		&:first-child {
			margin-right: 5px;
		}
	}
`;
